import React from "react";
import "./EventLog.css";

const EventLog = ({
  events,
  winner,
}: {
  events: GameEvent[];
  winner: Participant | null;
}) => (
  <div className="event-log">
    <div className="event-log--entry event-log--entry--init">
      <span>Starting simulation …</span>
    </div>

    {events.map((event, idx) => (
      <EventLogEntry key={idx} event={event} />
    ))}

    {winner && (
      <div className={`event-log--entry event-log--entry--result`}>
        <span>{winner === "PLAYER" ? "Player wins!" : "Dealer wins!"}</span>
      </div>
    )}
  </div>
);

const EventLogEntry = ({ event }: { event: GameEvent }) => (
  <div className="event-log--entry">
    {event.action === "PLAYER_DRAW" ? (
      <span>
        {event.cards.length === 1
          ? "Player asks for another card"
          : "Dealing cards to the player"}
      </span>
    ) : (
      <span>
        {event.cards.length === 1
          ? "Dealer draws another card"
          : "Dealing cards to the dealer"}
      </span>
    )}
    <span className="event-log--entry--cards">
      {event.cards.map((card, idx) => (
        <CardText key={idx} card={card} />
      ))}
    </span>
  </div>
);

const CardText = ({ card }: { card: Card }) => {
  return (
    <span className={`card--text suit--${card.suit.toLowerCase()}`}>
      {card.value}
    </span>
  );
};

export default EventLog;
