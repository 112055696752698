const API_URL = process.env.REACT_APP_API_URL || "";

const Api = {
  async playGame(): Promise<GameResult> {
    const response = await fetch(`${API_URL}/play`, { method: "POST" });
    const data = await response.json();
    return data;
  },
  async getShuffledDeck(): Promise<Card[]> {
    const response = await fetch(`${API_URL}/shuffle`);
    const data = await response.json();
    return data;
  },
  async getHealth(): Promise<{ status: string }> {
    const response = await fetch(`${API_URL}/health`);
    const data = await response.json();
    return data;
  },
};

export default Api;
