import { getCurrentScore, getScoreType } from "../util/BlackjackUtil";
import React from "react";
import CardIcon from "./CardIcon";
import "./Hand.css";

const GameHand = ({
  name,
  cards,
  hasWon,
}: {
  name: string;
  cards: Card[];
  hasWon: boolean;
}) => {
  const score = getCurrentScore(cards);
  const scoreType = getScoreType(score);

  return (
    <div className={`card--hand ${hasWon && "card--hand--won"}`}>
      {score > 0 && (
        <span
          className={`card--hand--score card--hand--score--${scoreType}`}
          key={score}
        >
          {score}
        </span>
      )}
      <h2 className="card--hand--title">{name}</h2>
      <CardList cards={cards} />
    </div>
  );
};

const CardList = ({ cards }: { cards: Card[] }) => (
  <div className="card--list">
    {cards.map((card, idx) => (
      <span className="card--list--entry" key={idx}>
        <CardIcon suit={card.suit} value={card.value} />
      </span>
    ))}
  </div>
);

export default GameHand;
