export function getScoreType(score: number) {
  if (score === 21) return "exact";

  return score > 21 ? "high" : "low";
}

export function getCurrentScore(cards: Card[]) {
  const score = cards
    .map((c) => c.numericValue)
    .reduce((prev, next) => prev + next, 0);

  return score;
}

export function getParticipantCards(
  events: GameEvent[],
  participant: Participant
): Card[] {
  const action = participant === "PLAYER" ? "PLAYER_DRAW" : "OPPONENT_DRAW";

  return events.filter((e) => e.action === action).flatMap((e) => e.cards);
}
