import React from "react";
import "./CardIcon.css";

const CardIcon = ({ suit, value }: { suit: Suit; value: Value }) => (
  <span
    className={`card card--suit--${suit.toLowerCase()} card--value--${value.toLowerCase()}`}
  >
    <span className="card--suit"></span>
    <span className="card--value">{value}</span>
  </span>
);

export default CardIcon;
