import React from "react";

import "./Main.css";
import BlackjackSimulator from "./BlackjackSimulator";

const Header = () => (
  <header className="header">
    <h1 className="header--title">Greyjack</h1>
    <h2 className="header--tagline">Simplified Blackjack Simulator</h2>
  </header>
);

const Main = () => (
  <div className="page">
    <Header />
    <main className="main">
      <BlackjackSimulator />
    </main>
  </div>
);

export default Main;
