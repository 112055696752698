import React, { useEffect, useState } from "react";

import "./BlackjackSimulator.css";
import Api from "../services/Api";
import GamePlayback from "../components/GamePlayback";

const BlackjackSimulator = () => {
  const [simulatorState, setSimulatorState] = useState<
    | "INITIALIZING"
    | "ERROR"
    | "READY"
    | "SIMULATION_LOADING"
    | "PLAYING"
    | "REQUESTING_SIMULATION"
  >("INITIALIZING");

  const [gameResult, setGameResult] = useState<GameResult | null>(null);

  useEffect(() => {
    if (simulatorState === "INITIALIZING") {
      // Trigger API call immediately to speed up the warm up the Heroku backend
      Api.getHealth()
        .then((resp) => {
          console.debug("API status:", resp.status);

          // Simulate a bit of delay for added excitement
          setTimeout(() => setSimulatorState("READY"), 500);
        })
        .catch((e) => {
          console.error("Error getting API health", e);
          setSimulatorState("ERROR");
        });
    } else if (simulatorState === "REQUESTING_SIMULATION") {
      setSimulatorState("SIMULATION_LOADING");
      Api.playGame()
        .then((data) => {
          setGameResult(data);
          setSimulatorState("PLAYING");
        })
        .catch((e) => {
          console.error("Error playing game", e);
          setSimulatorState("ERROR");
        });
    }
  }, [simulatorState]);

  switch (simulatorState) {
    case "INITIALIZING":
      return (
        <span className={"status-message"}>
          Initializing Blackjack Simulator …
        </span>
      );

    case "ERROR":
      return (
        <span className={"status-message"}>
          Error: Could not initialize Blackjack Simulator.{" "}
          <button
            className="button retry-button"
            onClick={() => setSimulatorState("INITIALIZING")}
          >
            Try again
          </button>{" "}
        </span>
      );

    case "READY":
      return (
        <>
          <span className={"status-message"}>
            Simulation engine initialized and ready to play.
          </span>
          <button
            className="start-simulation button"
            onClick={() => setSimulatorState("REQUESTING_SIMULATION")}
          >
            Create new simulation
          </button>
        </>
      );

    default:
      return gameResult ? (
        <GamePlayback
          key={simulatorState}
          gameResult={gameResult}
          gameSpeed={2500}
          onPlayAgain={() => setSimulatorState("REQUESTING_SIMULATION")}
        />
      ) : (
        <span className={"status-message"}>Creating new simulation …</span>
      );
  }
};

export default BlackjackSimulator;
