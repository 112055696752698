import React, { useEffect, useState } from "react";
import "./GamePlayback.css";
import GameView from "./GameView";

const GamePlayback = ({
  gameResult,
  gameSpeed,
  onPlayAgain,
}: {
  gameResult: GameResult;
  gameSpeed: number;
  onPlayAgain: () => any;
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [displayWinner, setDisplayWinner] = useState<Participant | null>(null);

  useEffect(() => {
    // Iterate through the events, with some delay between each step.
    // This is a bit difficult to understand, and should probably be cleaned up.
    if (currentStep < gameResult.events.length) {
      const id = setTimeout(() => {
        setCurrentStep((oldValue) => oldValue + 1);
      }, gameSpeed);

      return () => clearTimeout(id);
    } else if (currentStep === gameResult.events.length) {
      const id = setTimeout(
        () => setDisplayWinner(gameResult.winner),
        gameSpeed / 2
      );
      return () => clearTimeout(id);
    }
  }, [gameResult, currentStep, gameSpeed]);

  const visibleEvents = gameResult.events.slice(0, currentStep);

  return (
    <>
      <GameView events={visibleEvents} winner={displayWinner} />
      {displayWinner && (
        <button className="play-again button" onClick={() => onPlayAgain()}>
          Play again?
        </button>
      )}
    </>
  );
};

export default GamePlayback;
