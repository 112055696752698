import { getParticipantCards } from "../util/BlackjackUtil";
import GameHand from "./Hand";
import EventLog from "./EventLog";
import React from "react";
import "./GameView.css";

const GameView = ({
  events,
  winner,
}: {
  events: GameEvent[];
  winner: Participant | null;
}) => {
  const playerCards = getParticipantCards(events, "PLAYER");
  const opponentCards = getParticipantCards(events, "OPPONENT");

  return (
    <>
      <div className="card--hands">
        <GameHand
          name="Player"
          cards={playerCards}
          hasWon={winner === "PLAYER"}
        />
        <GameHand
          name="Dealer"
          cards={opponentCards}
          hasWon={winner === "OPPONENT"}
        />
      </div>
      <EventLog events={events} winner={winner} />
    </>
  );
};

export default GameView;
